import React from "react"
import {graphql} from "gatsby"
import MainLayout from "Layout/layout"
import IntroText from "Layout/IntroText/IntroText"

const Imprint = ({
  location,
  data
}) => {

  const {page, optionsPage} = data
  const gI = optionsPage.edges[0].node.global_data.generic_images

  return (
    <MainLayout location={location}>
      <IntroText intro={page.acf.intro_text} headline={page.title} genericImages={gI} classes="py-5" />
    </MainLayout>
  )
}

export const query = graphql`
  query imprintPageQuery {
    page: wordpressPage(slug: { eq: "imprint" }) {
      title
      id
      acf {
        intro_text {
          paragraphs {
            paragraph
          }
        }
      }
    }
    optionsPage: allWordpressAcfOptions {
      edges {
        node {
          global_data {
            generic_images {
              image {
                localFile {
                  childImageSharp {
                    fluid {
                      ...GatsbyImageSharpFluid_noBase64
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default Imprint
